import {
  type GraphTableDataI,
  type TotalVolumeTrend,
  type VolumeByArticle,
  type VolumeByArticleGroup,
} from '@stenarecycling/customer-portal-types';

import { formatNumberToCurrency, formatNumberToKgUnit, makeMonthLabels } from '../../utils';

export const makeTableDataVolumeArticles = (
  data: VolumeByArticle[],
  label: string,
  t: (key: string) => string,
  hideFinancial?: boolean,
  language?: string,
): GraphTableDataI => {
  const currencyCode = data[0]?.currencyCode ?? '';
  const columns = [t('analysisPage.table.articles'), t('analysisPage.table.weight')];

  if (!hideFinancial) columns.push(currencyCode);

  const rows = data.map((item) => {
    const row = [
      { value: item.articleName },
      {
        value: formatNumberToKgUnit(item.quantityKg, { locale: language }),
      },
    ];

    if (!hideFinancial) {
      row.push({
        value: formatNumberToCurrency(item.amount, currencyCode, { locale: language }),
      });
    }

    return row;
  });

  return {
    columns,
    rows,
    label,
  };
};
export const makeTableDataVolumeArticleGroups = (
  data: VolumeByArticleGroup[],
  label: string,
  t: (key: string) => string,
  hideFinancial?: boolean,
  language?: string,
): GraphTableDataI => {
  const currencyCode = data[0]?.currencyCode ?? '';
  const columns = [t('analysisPage.table.articlegroup'), t('analysisPage.table.weight')];

  if (!hideFinancial) columns.push(currencyCode);

  const rows = data.map((item) => {
    const row = [
      { value: item.articleGroupDescription },
      {
        value: formatNumberToKgUnit(item.quantityKg, { locale: language }),
      },
    ];

    if (!hideFinancial) {
      row.push({
        value: formatNumberToCurrency(item.amount, currencyCode, { locale: language }),
      });
    }

    return row;
  });

  return {
    columns,
    rows,
    label,
  };
};
export const makeTableDataVolumeTrends = (
  data: TotalVolumeTrend[],
  label: string,
  t: (key: string) => string,
  hideFinancial?: boolean,
  language?: string,
): GraphTableDataI => {
  const months = makeMonthLabels(data, t);
  const currencyCode = data[0]?.currencyCode ?? '';

  const columns = [t('analysisPage.table.month'), t('analysisPage.table.weight')];

  if (!hideFinancial) columns.push(currencyCode);

  const rows = data.map((item, index) => {
    const row = [
      { value: months[index] },
      {
        value: formatNumberToKgUnit(item.quantityKg, { locale: language }),
      },
    ];

    if (!hideFinancial) {
      row.push({
        value: formatNumberToCurrency(item.amount, currencyCode, { locale: language }),
      });
    }

    return row;
  });

  return {
    columns,
    rows,
    label,
  };
};
