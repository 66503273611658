/* eslint-disable @typescript-eslint/unbound-method */
import { ArrowRight, ArrowLeft } from '#icons';
import { DatePickerHeader, DatePickerMonthLabel } from '../../styles';
import { IconButton } from '../../../../IconButton';
import { type CustomHeaderDatepickerProps } from '../CustomHeaderDatepicker/CustomHeaderDatepicker';

const CustomHeaderMonthpicker = ({
  date,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  locale,
}: CustomHeaderDatepickerProps) => (
  <DatePickerHeader>
    <IconButton variant="secondary" disabled={prevMonthButtonDisabled} onClick={decreaseYear}>
      <ArrowLeft />
    </IconButton>

    <DatePickerMonthLabel>
      {date.toLocaleDateString(locale, { year: 'numeric' })}
    </DatePickerMonthLabel>

    <IconButton variant="secondary" disabled={nextMonthButtonDisabled} onClick={increaseYear}>
      <ArrowRight />
    </IconButton>
  </DatePickerHeader>
);

export default CustomHeaderMonthpicker;
