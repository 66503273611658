import styled from '@emotion/styled';
import { overlayColor } from '../../helpers/styleHelpers';

export const StyledOverlayOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${overlayColor};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
