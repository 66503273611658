import { type FunctionComponent } from 'react';
import { type IconProps } from '../types';
import { IconWrapper } from '../style';

export const ArrowUp: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        d="M11.75 21.25L11.75 2.75"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-0.5, -1)"
      />
      <path
        d="M4.75 9.75L11.75 2.75L18.75 9.75"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-0.5, -1)"
      />
    </svg>
  </IconWrapper>
);
