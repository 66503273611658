import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useTranslation } from 'react-i18next';
import { QuickRangeWrapper, PeriodButton } from './styles';

export const QuickRange = ({
  monthPicker,
  onChange,
}: {
  monthPicker?: boolean;
  onChange: (dates: [Date, Date]) => void;
}) => {
  const { t } = useTranslation();

  dayjs.extend(quarterOfYear);

  // Get last month date-range with dayjs
  const lastMonth = dayjs().subtract(1, 'month');
  const lastMonthStart = lastMonth.startOf('month').toDate();
  const lastMonthEnd = lastMonth.endOf('month').toDate();
  const lastYearFirstDay = dayjs().subtract(1, 'year').startOf('year').toDate();
  const lastYearLastDay = dayjs().subtract(1, 'year').endOf('year').toDate();
  const lastQuarterFirstDay = dayjs().subtract(1, 'quarter').startOf('quarter').toDate();
  const lastQuarterLastDay = dayjs().subtract(1, 'quarter').endOf('quarter').toDate();

  const thisMonthFirstDay = dayjs().startOf('month').toDate();
  const thisMonthLastDay = dayjs().endOf('month').toDate();

  const today = dayjs().toDate();
  const monthStartMinusOneYear = dayjs().startOf('month').subtract(1, 'year').toDate();
  const startOfYear = dayjs().startOf('year').toDate();

  return (
    <QuickRangeWrapper>
      {!monthPicker && (
        <>
          <PeriodButton
            onClick={() => {
              onChange([thisMonthFirstDay, thisMonthLastDay]);
            }}
          >
            {t('datepicker.thisMonth')}
          </PeriodButton>
          <PeriodButton
            onClick={() => {
              onChange([lastMonthStart, lastMonthEnd]);
            }}
          >
            {t('datepicker.lastMonth')}
          </PeriodButton>
        </>
      )}
      <PeriodButton
        onClick={() => {
          onChange([lastQuarterFirstDay, lastQuarterLastDay]);
        }}
      >
        {t('datepicker.lastQuarter')}
      </PeriodButton>

      <PeriodButton
        onClick={() => {
          onChange([lastYearFirstDay, lastYearLastDay]);
        }}
      >
        {t('datepicker.lastYear')}
      </PeriodButton>
      <PeriodButton
        onClick={() => {
          onChange([monthStartMinusOneYear, lastMonthEnd]);
        }}
      >
        {t('datepicker.last12')}
      </PeriodButton>
      <PeriodButton
        onClick={() => {
          onChange([startOfYear, today]);
        }}
      >
        {t('datepicker.yearToDate')}
      </PeriodButton>
    </QuickRangeWrapper>
  );
};
export default QuickRange;
