import styled from '@emotion/styled';
import { Button } from '#components/Input/Button';
import { breakpoint, colors, spacing, typography } from '#themes/stena-recycling';

export const PaginationWrapper = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing.small};
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${breakpoint.large}px) {
    align-items: center;
  }

  @media (max-width: ${breakpoint.medium}px) {
    flex-direction: column;
  }
`;

export const ShowingRowsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const PaginationButtonWrapper = styled.div`
  display: flex;
  flex: 0;
  gap: ${spacing.xxsmall};

  @media (max-width: ${breakpoint.large}px) {
    order: -1;
    flex-grow: 1;
    min-width: 100%;
    justify-content: center;
  }

  @media (max-width: ${breakpoint.medium}px) {
    order: unset;
  }
`;

export const PageNumberButton = styled(Button)<{ isActive: boolean }>`
  min-width: 34px;
  border-radius: 4px;
  height: 32px;
  padding: 12px;
`;

export const ResultsPerPageWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: ${spacing.xsmall};
  justify-content: flex-end;
  align-items: center;
`;

export const StyledEllipsis = styled.div`
  width: ${spacing.xsmall};
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  color: ${colors.monochrome.grey50};
  font-size: ${typography.heading.desktop.medium.fontSize};
  user-select: none;
`;

export const GoToPageWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
  justify-content: flex-end;
`;
