import { useContext, useMemo, useRef } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { DropdownV2, SimpleSelectList } from '../DropdownV2';
import { withSegmentedButtonsProvider } from './hooks/withSegmentedButtonsProvider';
import {
  DesktopStyle,
  MobileStyle,
  SegmentedButtonsWrapper,
  StyledSegmentedButtons,
  Wrapper,
} from './styles';
import { SegmentedButtonsContexts } from './context/SegmentedButtonsContext';
import { SegmentButton } from './components';
export * from './components';

export const SegmentedButtons = withSegmentedButtonsProvider(() => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { setSelectedValue, selectedValue } = useContext(SegmentedButtonsContexts.useCurrentIndex);

  const { items } = useContext(SegmentedButtonsContexts.props);

  const handleOnChange = (value: string[]) => {
    setSelectedValue(value[0]);
  };

  const dropDownItems = useMemo((): ListDropdownItem[] => {
    return items.map((item) => {
      return {
        label: item.label,
        value: item.value,
        type: 'value',
        children: [],
      };
    });
  }, [items]);

  return (
    <Wrapper>
      <DesktopStyle>
        <SegmentedButtonsWrapper>
          <StyledSegmentedButtons ref={parentRef}>
            {items.map((item) => (
              <SegmentButton
                key={item.value}
                value={item.value}
                label={item.label}
                testId={`segmentedButton-${item.value}`}
              />
            ))}
          </StyledSegmentedButtons>
        </SegmentedButtonsWrapper>
      </DesktopStyle>
      <MobileStyle>
        <DropdownV2
          items={dropDownItems}
          onChange={(value) => {
            handleOnChange(value);
          }}
          multiSelect={false}
          label=""
          selectedValues={selectedValue ? [selectedValue] : []}
          placeholder="Select a value"
        >
          <SimpleSelectList />
        </DropdownV2>
      </MobileStyle>
    </Wrapper>
  );
});
