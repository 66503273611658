import { type PropsWithChildren } from 'react';
import { Paragraph } from '#components/Typography/Paragraph';
import {
  Tooltip as TooltipComponent,
  TooltipContent,
  TooltipTrigger,
} from '#components/Data/TooltipV2';
import { colors } from '#themes/stena-recycling';
import { Info } from '#icons/Info';

export const Tooltip = ({ children }: PropsWithChildren) => {
  if (children) {
    return (
      <TooltipComponent placement="right">
        <TooltipTrigger>
          <Info width="22px" height="22px" cursor="pointer" color={colors.primary.blue} />
        </TooltipTrigger>
        <TooltipContent>
          <Paragraph variant="medium">{children}</Paragraph>
        </TooltipContent>
      </TooltipComponent>
    );
  }

  return null;
};
