import {
  type CO2ByArticleGroup,
  type CO2Trend,
  type TagSelectionProps,
  type WasteHierarchy,
} from '@stenarecycling/customer-portal-types';

import { graphBarColors, formatNumberToLocale } from '../../utils';

const makeTagsEnvironment = (sum: number, selectedDate: [string, string]): TagSelectionProps => ({
  date: {
    dateFrom: selectedDate[0],
    dateTo: selectedDate[1],
  },
  tags: [
    {
      label: formatNumberToLocale(sum, {
        style: 'unit',
        unit: 'kilogram',
      }),
      circleColor: graphBarColors[0],
    },
  ],
});

export const makeTagsEnvironmentWasteHierarchy = (
  data: WasteHierarchy,
  selectedDate: [string, string],
  options?: Record<string, string | number>,
): TagSelectionProps => {
  let { landfillKg, incinerationKg, bioEnergyKg, recyclingKg, reuseKg, otherKg } = data;

  const { unspecifiedKg } = data;

  landfillKg ??= 0;
  incinerationKg ??= 0;
  bioEnergyKg ??= 0;
  recyclingKg ??= 0;
  reuseKg ??= 0;
  otherKg ??= 0; // otherKg is never null but this is just cleaner

  const sum =
    landfillKg +
    incinerationKg +
    bioEnergyKg +
    recyclingKg +
    reuseKg +
    unspecifiedKg +
    (options?.country === '555' ? otherKg : 0);

  return makeTagsEnvironment(sum, selectedDate);
};

export const makeTagsFromEnvironmentEmissionTrend = (
  data: CO2Trend[],
  selectedDate: [string, string],
): TagSelectionProps => {
  const totalPeriodEmission = data.reduce((sum, current) => sum + current.cO2Kg, 0);

  return makeTagsEnvironment(totalPeriodEmission, selectedDate);
};

export const makeTagsEnvironmentC02Savings = (
  data: CO2ByArticleGroup[],
  selectedDate: [string, string],
): TagSelectionProps => {
  const totalCOKg = data[0]?.totalCO2Kg ?? 0;

  return makeTagsEnvironment(totalCOKg, selectedDate);
};
