import { type FunctionComponent } from 'react';
import { IconWrapper } from '../style';
import type { IconProps } from '../types';

export const Copy: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        d="M7.75 5C7.75 3.75736 8.75736 2.75 10 2.75H15.5858C15.6521 2.75 15.7157 2.77634 15.7626 2.82322L16.2856 2.30016L15.7626 2.82322L18.4697 5.53033L21.1768 8.23744C21.2237 8.28432 21.25 8.34791 21.25 8.41421V16C21.25 17.2426 20.2426 18.25 19 18.25H10C8.75736 18.25 7.75 17.2426 7.75 16V5Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        transform="translate(-0.5, -1)"
      />
      <path d="M16 3.5V8C16 8.55228 16.4477 9 17 9H21" stroke={color} strokeWidth="1.5" />
      <path
        d="M7.5 5H5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H14C15.6569 22 17 20.6569 17 19V18.5"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        transform="translate(-0.5, -1)"
      />
    </svg>
  </IconWrapper>
);
