import styled from '@emotion/styled';
import { breakpoint, spacing } from '../../../../../themes';
import { Paragraph } from '../../../../Typography';

export const EmptyTableWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: center;
  padding: ${spacing.mediumHigh};

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  @media (max-width: ${breakpoint.small}px) {
    padding: ${spacing.medium} ${spacing.small};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.xsmall};
  margin-top: ${spacing.mediumLow};

  @media (max-width: ${breakpoint.small}px) {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const StyledParagraph = styled(Paragraph)`
  @media (max-width: ${breakpoint.small}px) {
    text-align: center;
  }
`;
