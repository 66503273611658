import { useContext } from 'react';
import { Chevron, Info } from '../../../../Icons';
import { DropdownContexts } from '../../context/DropdownContext';
import { SkeletonLoader } from '../../../../Feedback/SkeletonLoader';
import { colors, spacing } from '../../../../../themes';
import { Label } from '../../../../Feedback/Label';
import { DropdownErrorMessage } from './subs/DropdownErrorMessage/DropdownErrorMessage';
import {
  ChevronWrapper,
  DropdownHeaderStyled,
  DropdownHeaderWrapper,
  DropdownLabelStyled,
  DropdownPlaceholderStyled,
  InfoIconWrapper,
  LabelWrapper,
  RequiredIndicator,
  SkeletonWrapper,
  StyledAdditionalSelectedWrapper,
  TopWrapper,
} from './styles';

export const DropdownHeader = () => {
  const { open, error, toggleOpen } = useContext(DropdownContexts.useDropdown);

  const { hasSelectedItems, dropdownText, additionalSelectedAmount, allSelected } = useContext(
    DropdownContexts.useValues,
  );
  const { disabled, label, loading, required, icon, showInfoIcon, onInfoIconClick } = useContext(
    DropdownContexts.props,
  );

  const isPlaceholderActive = !hasSelectedItems;

  return (
    <DropdownHeaderWrapper data-testid="dropdown-header">
      {(label ?? showInfoIcon) && (
        <TopWrapper>
          {label && (
            <LabelWrapper>
              {<DropdownLabelStyled>{label}</DropdownLabelStyled>}
              {required && <RequiredIndicator>*</RequiredIndicator>}
            </LabelWrapper>
          )}

          {showInfoIcon && (
            <InfoIconWrapper onClick={onInfoIconClick}>
              <Info color={colors.primary.blue} cursor={'pointer'} height={'22px'} width={'22px'} />
            </InfoIconWrapper>
          )}
        </TopWrapper>
      )}

      <DropdownHeaderStyled disabled={disabled} error={error} onClick={toggleOpen} open={open}>
        {!loading && (
          <>
            <DropdownPlaceholderStyled placholderActive={isPlaceholderActive}>
              {dropdownText}
            </DropdownPlaceholderStyled>

            {!allSelected && additionalSelectedAmount > 0 && (
              <StyledAdditionalSelectedWrapper>
                <Label variant="info">{`+${additionalSelectedAmount}`}</Label>
              </StyledAdditionalSelectedWrapper>
            )}
          </>
        )}

        {loading && (
          <SkeletonWrapper>
            <SkeletonLoader height={spacing.small} />
          </SkeletonWrapper>
        )}

        {icon}
        {!icon && (
          <ChevronWrapper>
            <Chevron height="24px" width="24px" rotation={open ? 180 : 0} />
          </ChevronWrapper>
        )}
      </DropdownHeaderStyled>

      <DropdownErrorMessage />
    </DropdownHeaderWrapper>
  );
};
