import { type Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IconButton } from '#components/Input/IconButton';
import { ChevronRight } from '#icons/ChevronRight';
import { ChevronLeft } from '#icons/ChevronLeft';
import { PageNumbers } from './PageNumbers';
import { ResultsPerPage } from './ResultsPerPage';
import { PaginationButtonWrapper, PaginationWrapper, ShowingRowsWrapper } from './styles';

type PaginationProps<T> = {
  table: Table<T>;
};

export const Pagination = <T,>({ table }: PaginationProps<T>) => {
  const pageCount = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const totalRows = table.getRowCount();
  const currentRows = table.getRowModel().rows.length;
  const startRow = totalRows === 0 ? 0 : currentPage * pageSize + 1;
  const endRow = Math.min(totalRows, currentPage * pageSize + currentRows);
  const { t } = useTranslation();

  const minimumResultsPerPage = 10;

  if (totalRows <= minimumResultsPerPage) {
    return null;
  }

  return (
    <PaginationWrapper aria-label="Pagination Navigation">
      <ShowingRowsWrapper>
        {t('table.pagination.showing-rows', {
          rows: `${startRow}-${endRow}`,
          totalRows: totalRows.toLocaleString(),
        })}
      </ShowingRowsWrapper>
      <PaginationButtonWrapper>
        <IconButton
          variant="secondary"
          aria-label="Previous page"
          onClick={() => {
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft />
        </IconButton>
        <PageNumbers
          currentPage={currentPage}
          pageCount={pageCount}
          onClick={(index) => {
            table.setPageIndex(index);
          }}
        />
        <IconButton
          variant="secondary"
          aria-label="Next page"
          onClick={() => {
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight />
        </IconButton>
      </PaginationButtonWrapper>

      <ResultsPerPage
        totalRows={totalRows}
        pageSize={pageSize}
        minimumResultsPerPage={minimumResultsPerPage}
        onChange={(newPageSize) => {
          table.setPageSize(newPageSize);
        }}
      />
    </PaginationWrapper>
  );
};
