import { breakpoint } from 'component-library/themes/stena-recycling';
import { useMediaQuery } from './useMediaQuery';

export function useBreakpoints() {
  const breakpoints = {
    isXs: useMediaQuery(`(max-width: ${breakpoint.small}px)`),
    isSm: useMediaQuery(
      `(min-width: ${breakpoint.small}px) and (max-width: ${breakpoint.medium}px)`,
    ),
    isMd: useMediaQuery(
      `(min-width: ${breakpoint.medium}px) and (max-width: ${breakpoint.large}px)`,
    ),
    isLg: useMediaQuery(`(min-width: ${breakpoint.large}px)`),
    active: 'xs',
  };

  if (breakpoints.isXs) {
    breakpoints.active = 'xs';
  }
  if (breakpoints.isSm) {
    breakpoints.active = 'sm';
  }
  if (breakpoints.isMd) {
    breakpoints.active = 'md';
  }
  if (breakpoints.isLg) {
    breakpoints.active = 'lg';
  }

  return breakpoints;
}
