import { Spinner } from '../Spinner';
import { StyledOverlay, StyledOverlayOuter } from './styles';

type SpinnerOverlayProps = {
  active: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
};

/**
 * Requires a container-wrapper to be positioned relative, see TableV2 for example
 */
export const SpinnerOverlay = ({ active, size = 'xlarge' }: SpinnerOverlayProps) => {
  if (!active) {
    return null;
  }

  return (
    <StyledOverlayOuter>
      <StyledOverlay>
        <Spinner size={size} />
      </StyledOverlay>
    </StyledOverlayOuter>
  );
};
