import i18n from 'i18next';

type FormatNumberOptions = Intl.NumberFormatOptions & {
  locale?: string;
  removeTrailingZeros?: boolean;
};

export const formatNumberToLocale = (
  number: number | string,
  options: FormatNumberOptions = {},
): string => {
  const userLanguage = i18n.resolvedLanguage ?? i18n.language;

  const defaultOptions = {
    locale: userLanguage,
  };

  // Remove locale if not specified to fallback to default
  if (options.locale === undefined) {
    delete options.locale;
  }

  const formatOptions = { ...defaultOptions, ...options };

  const parsedNum = parseFloat(`${number}`);

  if (isNaN(parsedNum)) return number as string;

  if (formatOptions.style === 'percent' && parsedNum < 0.00005 && parsedNum > 0) {
    const value = (0.0001).toLocaleString(formatOptions.locale, {
      minimumFractionDigits: 2,
      style: 'percent',
    });

    return `< ${value}`;
  }

  if (formatOptions.removeTrailingZeros) {
    formatOptions.trailingZeroDisplay = 'stripIfInteger';
  }

  const numberString = parsedNum.toLocaleString(formatOptions.locale, {
    minimumFractionDigits: formatOptions.style === 'percent' ? 2 : 0,
    maximumFractionDigits: 2,
    ...formatOptions,
  });

  return numberString;
};

export const formatNumberToCurrency = (
  number: number | string,
  currencyCode: string,
  options: FormatNumberOptions = {},
) => {
  if (currencyCode === '') return '';

  const currencyString = formatNumberToLocale(number, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    removeTrailingZeros: true,
    ...options,
  });

  if (currencyCode === 'NOK' && currencyString) {
    return currencyString.replace('Nkr', 'NOK');
  }

  return currencyString;
};

type FormatNumberToKgUnitOptions = FormatNumberOptions & {
  removeUnit?: boolean;
};
export const formatNumberToKgUnit = (
  number: number | string,
  options: FormatNumberToKgUnitOptions = {},
) => {
  const defaultOptions = {
    style: 'unit',
    unit: 'kilogram',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    removeTrailingZeros: true,
  } satisfies FormatNumberToKgUnitOptions;

  const formatOptions = { ...defaultOptions, ...options };

  if (formatOptions.removeUnit) {
    formatOptions.style = 'decimal';
  }

  const value = formatNumberToLocale(number, formatOptions);

  return value;
};
