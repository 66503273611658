import { type FunctionComponent } from 'react';
import { IconWrapper } from '../style';
import type { IconProps } from '../types';

export const Lock: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        d="M18 11H6C5.44772 11 5 11.4477 5 12V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V12C19 11.4477 18.5523 11 18 11Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="square"
        transform="translate(-1, -1)"
      ></path>
      <path
        d="M12 3C14.7614 3 17 5.23858 17 8V11H7V8C7 5.23858 9.23858 3 12 3Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="square"
        transform="translate(-1, -1)"
      ></path>
    </svg>
  </IconWrapper>
);
