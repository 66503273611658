import styled from '@emotion/styled';
import { breakpoint, colors, spacing, typography } from '../../../themes';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: ${spacing.small};
  position: relative;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  border: 1px solid ${colors.monochrome.grey20};
  background-color: ${colors.base.white};

  @media (max-width: ${breakpoint.large}px) {
    overflow-x: scroll;
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: ${colors.base.white};
  table-layout: fixed;
  min-width: calc(4px + ${breakpoint.large}px - 2 * ${spacing.mediumHigh});

  @media (max-width: ${breakpoint.large}px) {
    table-layout: auto;
  }
`;

export const TableHeader = styled.thead`
  padding: 0 ${spacing.xsmall};
  border-bottom: 1px solid ${colors.monochrome.grey50};
  min-height: 57px;
`;

export const TableHeaderRow = styled.tr``;

export const TableHeaderCell = styled.th`
  color: ${colors.primary.grey};
  padding: ${spacing.xsmall};
  text-align: left;
  vertical-align: middle;
  ${typography.body.mediumBold};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.monochrome.grey20};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const TableCell = styled.td`
  padding: ${spacing.xsmall};
  font-family: inherit;
  ${typography.body.large};
  vertical-align: middle;
`;

export const TableCellExpanded = styled(TableCell)`
  padding: 0;
`;

export const EmptyTableWrapper = styled.div`
  border: 1px solid ${colors.monochrome.grey20};
`;
