import { type FunctionComponent } from 'react';
import { IconWrapper } from '../style';
import type { IconProps } from '../types';

export const Plus: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={color ?? 'currentColor'}
        d="M7 1c0-.552285.44772-1 1-1s1 .447715 1 1v14c0 .5523-.44772 1-1 1s-1-.4477-1-1V1z"
        transform="translate(3, 3)"
      />
      <path
        fill={color ?? 'currentColor'}
        d="M1 9C.447715 9 2e-8 8.55228 0 8c-2e-8-.55228.447715-1 1-1h14c.5523 0 1 .44771 1 1 0 .55228-.4477 1-1 1H1z"
        transform="translate(3, 3)"
      />
    </svg>
  </IconWrapper>
);
