import {
  type ColumnDef,
  type ColumnFiltersState,
  type ExpandedState,
  type InitialTableState,
  type OnChangeFn,
  type PaginationState,
  type RowData,
  type TableMeta,
  type TableState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, type ReactNode, useState } from 'react';
import { IconButton } from '#components/Input/IconButton';
import { Chevron } from '#icons/Chevron';
import { SkeletonLoader } from '#components/Feedback/SkeletonLoader';
import { TableEmpty } from '#components/Data/Table/Components/TableEmpty';
import { SpinnerOverlay } from '#components/Feedback/SpinnerOverlay';
import {
  EmptyTableWrapper,
  StyledTable,
  TableBody,
  TableCell,
  TableCellExpanded,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableWrapper,
} from './style';
import { Filter } from './subs/Filter';
import { Pagination } from './subs/Pagination';
import { ColumnFilter } from './subs/ColumnFilter';
import { type ExpandableData } from './types';
import { TableHeaderSorting } from './subs/TableHeaderSorting';
import { Tooltip } from './subs/Tooltip/Tooltip';

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select';
    tooltip?: ReactNode;
    align?: 'left' | 'center' | 'right';
  }
}

export type TableV2Props<TData> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  data: TData[];

  allowFiltering?: boolean;
  pagination?: boolean;
  columnFiltering?: boolean;
  expandable?: boolean;
  emptyState?: React.ReactNode;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: TableMeta<any>;
  initialState?: InitialTableState;
  sorting?: boolean;
  paginationState?: PaginationState;
  fetchingNextPage?: boolean;
  state?: Partial<TableState>;

  /**
   * If this function is provided, it will be called when the pagination state changes and you will be expected to manage the state yourself. You can pass the managed state back to the table via the `tableOptions.state.pagination` option.
   * @link [API Docs](https://tanstack.com/table/v8/docs/api/features/pagination#onpaginationchange)
   * @link [Guide](https://tanstack.com/table/v8/docs/guide/pagination)
   */
  onPaginationChange?: OnChangeFn<PaginationState>;
  /**
   * When manually controlling pagination, you can supply a total `pageCount` value to the table if you know it (Or supply a `rowCount` and `pageCount` will be calculated). If you do not know how many pages there are, you can set this to `-1`.
   * @link [API Docs](https://tanstack.com/table/v8/docs/api/features/pagination#pagecount)
   * @link [Guide](https://tanstack.com/table/v8/docs/guide/pagination)
   */
  pageCount?: number;
  /**
   * When manually controlling pagination, you can supply a total `rowCount` value to the table if you know it. The `pageCount` can be calculated from this value and the `pageSize`.
   * @link [API Docs](https://tanstack.com/table/v8/docs/api/features/pagination#rowcount)
   * @link [Guide](https://tanstack.com/table/v8/docs/guide/pagination)
   */
  rowCount?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const expandColumn = <TData,>(): ColumnDef<TData, any> => ({
  id: 'select',
  size: 64,
  cell: ({ row }) => (
    <IconButton
      variant="secondary"
      onClick={() => {
        row.toggleExpanded();
      }}
      data-testid="expand-accordion"
    >
      {row.getIsExpanded() ? <Chevron rotation={180} /> : <Chevron />}
    </IconButton>
  ),
});

const getDefaultColumns = <TData,>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultColumns: ColumnDef<TData, any>[],
  expandable: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<TData, any>[] => {
  if (!expandable) {
    return defaultColumns;
  }

  return [...defaultColumns, expandColumn()];
};

/**
 * TableV2
 * @param allowFiltering: boolean - default false
 */
export const TableV2 = <TData,>({
  columns: defaultColumns,
  data,
  allowFiltering,
  pagination,
  expandable,
  columnFiltering,
  sorting = true,
  emptyState,
  loading,
  meta,
  pageCount,
  rowCount,
  initialState,
  onPaginationChange,
  paginationState,
  fetchingNextPage = false,
  state = {},
}: TableV2Props<TData>) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columns] = useState<typeof defaultColumns>(
    getDefaultColumns(defaultColumns, !!expandable),
  );
  const [columnVisibility, setColumnVisibility] = useState({});

  const manualPagination = pagination && !!onPaginationChange;

  const table = useReactTable<TData>({
    data,
    columns,
    defaultColumn: {
      size: -1,
    },
    filterFns: {},
    initialState,
    state: {
      columnFilters,
      columnVisibility,
      expanded,
      ...(paginationState ? { pagination: paginationState } : {}),
      ...state,
    },
    manualPagination,
    pageCount,
    rowCount,
    enableSorting: sorting,
    onExpandedChange: setExpanded,
    getExpandedRowModel: expandable ? getExpandedRowModel() : undefined,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    meta,
    ...(onPaginationChange ? { onPaginationChange } : {}),
  });

  const hasColumnFilters = columnFilters.length > 0;

  const rowModel = table.getRowModel();

  const hasRows = rowModel.rows.length > 0;

  if (!hasRows && !loading && !hasColumnFilters) {
    return <EmptyTableWrapper>{emptyState || <TableEmpty />}</EmptyTableWrapper>;
  }

  return (
    <TableWrapper>
      <SpinnerOverlay active={!!fetchingNextPage} />
      {columnFiltering && <ColumnFilter table={table} />}
      <TableContainer>
        <StyledTable>
          <colgroup>
            {table.getVisibleFlatColumns().map((column) => {
              if ('accessorKey' in column.columnDef) {
                return (
                  <col
                    key={column.columnDef.accessorKey.toString()}
                    style={{ width: column.columnDef.size }}
                  />
                );
              }

              return <col key={column.id} style={{ width: column.columnDef.size }} />;
            })}
          </colgroup>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableHeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeaderCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <TableHeaderSorting
                            align={header.column.columnDef.meta?.align}
                            isSorted={header.column.getIsSorted()}
                            isSortable={header.column.getCanSort()}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            <Tooltip>{header.column.columnDef.meta?.tooltip}</Tooltip>
                          </TableHeaderSorting>
                          {allowFiltering && header.column.getCanFilter() && (
                            <div>
                              <Filter column={header.column} />
                            </div>
                          )}
                        </>
                      )}
                    </TableHeaderCell>
                  ))}
                </TableHeaderRow>
              );
            })}
          </TableHeader>
          <TableBody>
            {loading &&
              Array.from({ length: 3 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell colSpan={columns.length}>
                    <SkeletonLoader />
                  </TableCell>
                </TableRow>
              ))}
            {rowModel.rows.map((row) => (
              <Fragment key={row.id}>
                <TableRow key={row.id} data-testid={`table-row`}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id} data-testid={`table-cell`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {row.getIsExpanded() && (
                  <TableRow key={`${row.id}-expanded`}>
                    <TableCellExpanded colSpan={row.getVisibleCells().length}>
                      {flexRender((row.original as ExpandableData).expandedContent, {})}
                    </TableCellExpanded>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {!loading && pagination && <Pagination table={table} />}
    </TableWrapper>
  );
};
