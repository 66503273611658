import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  type $DateRangeType,
  type CO2Trend,
  type dataFieldObj,
  EnvironmentGraphTypesI,
  FinancialGraphTypesI,
  type FinancialsTrend,
  FinancialSubGraphTypesI,
  GraphComparingTypesI,
  type graphTypesI,
  type PartnerTreeNodeV2,
  type TotalVolumeTrend,
  type TransportTrend,
  VolumeGraphTypesI,
} from '@stenarecycling/customer-portal-types';
import { getParentBPId, getPartnerDisplay } from '../../../businessPartners';
import { graphBarColors, graphComparePeriodBarColors, graphLineColors } from './graphColors';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Stockholm');

export const makeLabels = (
  columns: dataFieldObj,
  shouldTranslate?: boolean,
  t?: (key: string) => string,
) => {
  return columns.map((column) => {
    const label = Object.keys(column)[0];

    if (shouldTranslate && t) {
      return t(`graph.column.${label}`);
    }

    return label;
  });
};

export const makeMonthLabels = (
  data: CO2Trend[] | TotalVolumeTrend[] | FinancialsTrend[] | TransportTrend[],
  t: (key: string) => string,
) => {
  return data.map((item) => {
    return t(`graph.shipmentMonth.${item.shipmentMonth}`);
  });
};

// This function provides colors for Tags,
export const getMappedGraphColorForTagss = (
  isLineChart: boolean,
  currentIndex: { lineIndex: number; barIndex: number },
  compare: boolean,
  compareType: GraphComparingTypesI,
): {
  color: string;
  barIndex: number;
  lineIndex: number;
} => {
  let color;
  let { lineIndex, barIndex } = currentIndex;

  if (isLineChart) {
    color = graphLineColors[lineIndex];
    lineIndex += 1;
  } else {
    color =
      compare && compareType === GraphComparingTypesI.PERIOD
        ? graphComparePeriodBarColors[barIndex]
        : graphBarColors[barIndex];

    barIndex += 1;
  }

  return {
    color,
    lineIndex,
    barIndex,
  };
};

// This function provides colors for Tags, notice that's it's a function that returns a function.
export const getMappedGraphColorForTags = (): ((
  isLineChart: boolean,
  compare: boolean,
  compareType: GraphComparingTypesI,
) => string) => {
  let lineIndex = 0;
  let barIndex = 0;

  return (isLineChart: boolean, compare: boolean, compareType: GraphComparingTypesI) => {
    let color;

    if (isLineChart) {
      color = graphLineColors[lineIndex];
      lineIndex += 1;
    } else {
      color =
        compare && compareType === GraphComparingTypesI.PERIOD
          ? graphComparePeriodBarColors[barIndex]
          : graphBarColors[barIndex];

      barIndex += 1;
    }

    return color;
  };
};

export const getPickupName = (partner: PartnerTreeNodeV2 | undefined) => {
  if (!partner) return '';
  const { nameAndText } = getPartnerDisplay(partner.item);

  const id = getParentBPId(partner.item.dwKey);

  return `${nameAndText} (${id})`;
};

export const shouldPeriodBeLocked = (inputPeriod: graphTypesI, excludes?: graphTypesI[]) => {
  const lockedPeriods: string[] = [
    FinancialGraphTypesI.REVENUE_TREND,
    EnvironmentGraphTypesI.EMISSION_TREND,
    VolumeGraphTypesI.VOLUME_TRENDS,
    FinancialSubGraphTypesI.MATERIAL_VOLUME,
    FinancialSubGraphTypesI.TOTAL_REVENUE,
    FinancialSubGraphTypesI.TRANSPORT_COST,
  ];

  if (excludes?.includes(inputPeriod)) return false;

  return lockedPeriods.includes(inputPeriod);
};

export const getPeriodLabel = (dateSpan: $DateRangeType) => dateSpan.join(' → ');

export const getLockedPeriodDateRange = (): {
  userDates: $DateRangeType;
  apiDates: $DateRangeType;
} => {
  const today = new Date(Date.now()); // Date.now allows for easier testing

  const firstDate1YearAgo = dayjs(today).subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
  const lastDateLastMonth = dayjs(today).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
  const firstDateThisMonth = dayjs(today).startOf('month').format('YYYY-MM-DD');

  const userDates: $DateRangeType = [firstDate1YearAgo, lastDateLastMonth];
  const apiDates: $DateRangeType = [firstDate1YearAgo, firstDateThisMonth];

  return {
    userDates,
    apiDates,
  };
};
