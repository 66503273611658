import { createContext } from 'react';
import { useDropdownCore } from '../hooks/useDropdownCore/useDropdownCore';
import { useDropdownSearch } from '../hooks/useDropdownSearch/useDropdownSearch';
import { useDropdownValues } from '../hooks/useDropdownValues/useDropdownValues';
import { useDropdownItems } from '../hooks/useDropdownItems/useDropdownItems';
import { type DropdownProviderProps } from '../types/dropdown';

export const DropdownProvider = (props: DropdownProviderProps) => {
  const { children } = props;

  const useItems = useDropdownItems({
    items: props.items,
  });

  const useDropdown = useDropdownCore({
    errorMessage: props.errorMessage,
    error: props.error,
  });

  const useValues = useDropdownValues({
    multiSelect: props.multiSelect,
    onChange: props.onChange,
    onGroupChange: props.onGroupChange,
    selectedValues: props.selectedValues,
    items: props.items,
    placeholder: props.placeholder,
    disabled: props.disabled || false,
    autoSelect: props.autoSelect,
    setOpenChildren: useDropdown.setOpenChildren,
  });

  const useSearch = useDropdownSearch({
    items: props.items,
    customSearch: props.customSearch,
  });

  return (
    <DropdownContexts.useDropdown.Provider value={useDropdown}>
      <DropdownContexts.useValues.Provider value={useValues}>
        <DropdownContexts.useSearch.Provider value={useSearch}>
          <DropdownContexts.useItems.Provider value={useItems}>
            <DropdownContexts.props.Provider value={props}>
              {children}
            </DropdownContexts.props.Provider>
          </DropdownContexts.useItems.Provider>
        </DropdownContexts.useSearch.Provider>
      </DropdownContexts.useValues.Provider>
    </DropdownContexts.useDropdown.Provider>
  );
};

export const DropdownContexts = {
  useItems: createContext<ReturnType<typeof useDropdownItems>>({
    items: [],
    setItems: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
  }),
  useDropdown: createContext<ReturnType<typeof useDropdownCore>>({
    errorMessage: '',
    error: false,
    open: false,
    setError: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    setErrorMessage: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    toggleOpen: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    setOpenChildren: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    toggleOpenChild: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    closeAllChildren: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    dropdownRef: { current: null },
    isChildOpen: () => false,
  }),
  useSearch: createContext<ReturnType<typeof useDropdownSearch>>({
    searchActive: false,
    searchResults: [],
    onSearch: () => Promise.resolve(),
    resetSearch: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    searchText: '',
    hasSearchResults: false,
    searchResultsEmpty: false,
  }),
  useValues: createContext<ReturnType<typeof useDropdownValues>>({
    isAllSelected: () => false,
    toggleSelectAll: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    toggleItem: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    additionalSelectedAmount: 0,
    selectedItems: [],
    dropdownText: '',
    hasSelectedItems: false,
    isItemSelected: () => false,
    toggleValues: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    allSelected: false,
  }),
  props: createContext<DropdownProviderProps>({
    label: '',
    multiSelect: false,
    children: null,
    disabled: false,
    items: [],
    placeholder: '',
    selectedValues: [],
    searchPlaceholder: 'search',
    onChange: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
    required: false,
    icon: null,
    showInfoIcon: false,
    onInfoIconClick: () => {
      throw new Error(
        'No DropdownProvider found, please wrap <DropdownProvider> around your component',
      );
    },
  }),
};
