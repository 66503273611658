import { useTranslation } from 'react-i18next';
import { DropdownV2, SimpleSelectList } from '#components/Input/DropdownV2';
import { ResultsPerPageWrapper } from './styles';

type ResultsPerPageProps = {
  totalRows: number;
  pageSize: number;
  minimumResultsPerPage: number;
  onChange: (newPageSize: number) => void;
};

function generateResultsPerPageOptions(totalRows: number, minimumResultsPerPage: number): number[] {
  const numberOfOptions = 5;
  const options: number[] = [];

  if (totalRows <= minimumResultsPerPage) {
    return [minimumResultsPerPage];
  }

  let maxOption =
    totalRows <= 50 ? Math.ceil(totalRows / 10) * 10 : Math.ceil(totalRows / 2 / 10) * 10;

  if (maxOption > 100) {
    maxOption = Math.ceil(maxOption / 50) * 50;
  }

  if (maxOption > 200) {
    maxOption = 200;
  }

  const step = (maxOption - minimumResultsPerPage) / (numberOfOptions - 1);

  for (let i = 0; i < numberOfOptions; i++) {
    const option = minimumResultsPerPage + i * step;
    let roundedOption = Math.round(option / 10) * 10;

    if (roundedOption > 100) {
      roundedOption = Math.ceil(roundedOption / 50) * 50;
    }

    if (!options.includes(roundedOption) && roundedOption <= maxOption) {
      options.push(roundedOption);
    }
  }
  if (options[options.length - 1] !== maxOption) {
    options.push(maxOption);
  }

  return options;
}

export const ResultsPerPage = ({
  totalRows,
  pageSize,
  minimumResultsPerPage,
  onChange,
}: ResultsPerPageProps) => {
  const { t } = useTranslation();
  const options = generateResultsPerPageOptions(totalRows, minimumResultsPerPage);

  return (
    <ResultsPerPageWrapper>
      {t('table.pagination.results-per-page')}{' '}
      <DropdownV2
        onChange={(values: string[]) => {
          onChange(Number(values[0]));
        }}
        items={options.map((opt) => ({
          children: [],
          label: opt.toString(),
          type: 'value',
          value: opt.toString(),
        }))}
        placeholder={pageSize.toString()}
        selectedValues={[pageSize.toString()]}
        disabled={options.length === 1}
        aria-label="Select rows per page"
      >
        <SimpleSelectList />
      </DropdownV2>
    </ResultsPerPageWrapper>
  );
};
