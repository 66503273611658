import { type PartnerResItem } from '@stenarecycling/customer-portal-types';

export const getPartnerDisplay = (partner: PartnerResItem) => {
  const partnerInfo = getPartnerProps(partner);

  let nameAndText = partnerInfo.name;

  if (
    partnerInfo.description &&
    !descriptionSameAsName(partnerInfo.name, partnerInfo.description)
  ) {
    nameAndText += `, ${partnerInfo.description}`;
  }

  let desc = '';

  desc += partnerInfo.address.street;
  desc += partnerInfo.address.street ? ', ' : '';
  desc += partnerInfo.address.city;

  const isFnoAndLocation =
    (partner.source === 'FNO' || partner.source === 'CS') && partner.type === 'DELIVERYADRESS';

  const bpId = isFnoAndLocation ? getParentBPId(partner.dwKey) : partner.id;

  if (bpId) {
    desc += ` (${bpId})`;
  }

  return {
    nameAndText,
    addressAndId: desc,
  };
};

const descriptionSameAsName = (name: string, description: string) => {
  const nameClean = name.replace('(Ej Syd)', '').replace('(Syd)', '').trim();

  return description === nameClean;
};

export const getParentBPId = (partnerId: string) => {
  const [, bpId] = partnerId.split('_');

  return bpId;
};

export const getPartnerProps = (res: PartnerResItem) => {
  const isFno = res.source === 'FNO';
  const isCS = res.source === 'CS';

  const text = res.customerText ? res.customerText.replace('N/A', '') : '';
  const addDesc = res.additionalDescription ? res.additionalDescription.replace('N/A', '') : '';
  const street = res.street ? res.street.replace('N/A', '') : '';

  let description = text || addDesc;
  let name = res.description ? res.description : res.name;

  if (isFno || isCS) {
    description = res.description || description;
    name = res.name;
  }

  return {
    id: res.id,

    name,
    description,

    address: {
      street,
      city: res.city,
      zipcode: res.postalCode,
    },
  };
};
