import { type FunctionComponent } from 'react';
import { type IconProps } from '../types';
import { IconWrapper } from '../style';

export const Share: FunctionComponent<IconProps> = ({
  color,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height} cursor={cursor}>
      <svg
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={color ?? 'currentColor'}
          fillRule="evenodd"
          d="M12 2.25a.75.75 0 0 1 .53.22l4 4a.75.75 0 0 1-1.06 1.06l-2.72-2.72v9.69a.75.75 0 0 1-1.5 0V4.81L8.53 7.53a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 .53-.22ZM4.75 12a.75.75 0 0 0-1.5 0v7A2.75 2.75 0 0 0 6 21.75h12A2.75 2.75 0 0 0 20.75 19v-7a.75.75 0 0 0-1.5 0v7A1.25 1.25 0 0 1 18 20.25H6A1.25 1.25 0 0 1 4.75 19v-7Z"
          clipRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
};
