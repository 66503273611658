import { type FunctionComponent } from 'react';
import { IconWrapper } from '../style';
import type { IconProps } from '../types';

export const RequestPickup: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      transform={`rotate(${rotation ?? 0})`}
    >
      <path
        fill={color ?? 'currentColor'}
        fillRule="evenodd"
        d="M18.6 3H5.4C4.1 3 3 4.2 3 5.63v9.9c0 1.48 1.1 2.63 2.4 2.63h1.12v2.12c-.04.55.55.92.97.6l3.48-2.72h7.64c1.34 0 2.39-1.15 2.39-2.63v-9.9C21 4.2 19.95 3 18.6 3ZM5.4 4.38h13.2c.64 0 1.18.56 1.18 1.25v9.9c0 .7-.54 1.3-1.17 1.3h-7.85c-.12 0-.3.04-.38.13L7.74 19v-1.47c0-.42-.25-.7-.63-.7H5.4c-.63 0-1.13-.6-1.13-1.29v-9.9c0-.7.5-1.25 1.13-1.25ZM8.74 8a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Zm0 3a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"
        clipRule="evenodd"
      />
    </svg>
  </IconWrapper>
);
