import { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { Close } from '#icons';
import { IconButton } from '../../Input/IconButton';
import { Paragraph } from '../../Typography/Paragraph';
import { useSnackbar } from './SnackbarProvider';
import { SnackbarContainer, SnackbarMessage, SnackbarWrapper } from './styles';

export const Snackbar = () => {
  const nodeRef = useRef(null);
  const defaultHideDuration = 5000;
  const timer = useRef<NodeJS.Timeout>();

  const {
    hideSnackbar,
    snackbarState: { show, message, severity, autoHide, hideDuration, small },
  } = useSnackbar();

  useEffect(() => {
    if (show && autoHide) {
      timer.current = setTimeout(() => {
        hideSnackbar();
      }, hideDuration || defaultHideDuration);

      return () => {
        clearTimeout(timer.current);
      };
    }

    return;
  }, [show, hideSnackbar, timer, autoHide, hideDuration]);

  const handleOnClose = () => {
    hideSnackbar();
    clearTimeout(timer.current);
  };

  const handleOnMouseEnter = () => {
    clearTimeout(timer.current);
  };

  const handleOnMouseLeave = () => {
    if (autoHide) {
      timer.current = setTimeout(() => {
        hideSnackbar();
      }, hideDuration || defaultHideDuration);
    }
  };

  return (
    <Transition nodeRef={nodeRef} in={show} timeout={2000}>
      {(status) => (
        <div
          ref={nodeRef}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          role="alert"
        >
          <SnackbarWrapper transitionStatus={status} small={small}>
            <div>
              <SnackbarContainer severity={severity} small={small}>
                <SnackbarMessage>
                  {message.title && (
                    <Paragraph variant="large" bold>
                      {message.title}
                    </Paragraph>
                  )}
                  <Paragraph variant="large">{message.description}</Paragraph>
                </SnackbarMessage>
                {!small && (
                  <IconButton onClick={handleOnClose} variant="secondary">
                    <Close />
                  </IconButton>
                )}
              </SnackbarContainer>
            </div>
          </SnackbarWrapper>
        </div>
      )}
    </Transition>
  );
};
