import { type PropsWithChildren } from 'react';
import { CalendarContainer } from 'react-datepicker';
import { QuickRange } from './CustomFooterDatepicker/QuickRange';

export const CustomCalendarContainer = (
  onChange: (date: [Date, Date]) => void,
  showQuickRange?: boolean,
  monthPicker?: boolean,
) => {
  const CalendarContainerComponent = ({
    className,
    children,
  }: PropsWithChildren<{ className: string }>) => {
    return (
      <CalendarContainer className={className} aria-label="Choose Date" aria-modal="true">
        {showQuickRange && <QuickRange monthPicker={monthPicker} onChange={onChange} />}
        <div>{children}</div>
      </CalendarContainer>
    );
  };

  CalendarContainerComponent.displayName = 'CustomCalendarContainer';

  return CalendarContainerComponent;
};
