import { useBreakpoints } from 'customer-portal-helpers/useBreakpoints';
import { PageNumber } from './PageNumber';
import { StyledEllipsis } from './styles';

type PageNumbersProps = {
  currentPage: number;
  pageCount: number;
  onClick: (index: number) => void;
};

const Ellipsis = ({ ...args }) => <StyledEllipsis {...args}>&hellip;</StyledEllipsis>;

export const PageNumbers = ({ currentPage, pageCount, onClick }: PageNumbersProps) => {
  const { isSm, isXs } = useBreakpoints();

  const pageButtons = [];
  const numberOfPageNumbers = isSm || isXs ? 3 : 5; // desired window size excluding first and last buttons

  // Always show first page
  pageButtons.push(<PageNumber key={0} index={0} currentPage={currentPage} onClick={onClick} />);

  // Calculate window start and end (exclude first and last)
  const windowSize = Math.min(numberOfPageNumbers, pageCount - 2);
  let startPage = Math.max(1, currentPage - Math.floor(windowSize / 2));
  let endPage = startPage + windowSize - 1;

  if (endPage > pageCount - 2) {
    endPage = pageCount - 2;
    startPage = Math.max(1, endPage - windowSize + 1);
  }

  // Show ellipsis if gap exists after first page
  if (startPage > 1) {
    pageButtons.push(<Ellipsis key="start-ellipsis" />);
  }

  // Render window of page numbers
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(<PageNumber key={i} index={i} currentPage={currentPage} onClick={onClick} />);
  }

  // Show ellipsis if gap exists before last page
  if (endPage < pageCount - 2) {
    pageButtons.push(<Ellipsis key="end-ellipsis" />);
  }

  // Always show last page if there's more than one page
  if (pageCount > 1) {
    pageButtons.push(
      <PageNumber
        key={pageCount - 1}
        index={pageCount - 1}
        currentPage={currentPage}
        onClick={onClick}
      />,
    );
  }

  return pageButtons;
};
