import { type FunctionComponent } from 'react';
import { type IconProps } from '../types';
import { IconWrapper } from '../style';

export const Document: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color ?? 'currentColor'}
        d="M9 17.75h6c.21 0 .4-.07.53-.22a.73.73 0 0 0 .22-.53c0-.21-.07-.4-.22-.53a.73.73 0 0 0-.53-.22H9c-.21 0-.4.07-.53.22a.73.73 0 0 0-.22.53c0 .21.07.4.22.53a.7.7 0 0 0 .53.22Zm0-4h6c.21 0 .4-.07.53-.22a.73.73 0 0 0 .22-.53c0-.21-.07-.4-.22-.53a.73.73 0 0 0-.53-.22H9c-.21 0-.4.07-.53.22a.73.73 0 0 0-.22.53c0 .21.07.4.22.53a.7.7 0 0 0 .53.22ZM6.3 21.5c-.5 0-.92-.18-1.27-.52a1.74 1.74 0 0 1-.53-1.29V4.31c0-.5.17-.93.53-1.28.34-.35.77-.53 1.28-.53h7.2a1.77 1.77 0 0 1 1.27.53l4.2 4.2a1.8 1.8 0 0 1 .52 1.27v11.2a1.78 1.78 0 0 1-1.81 1.8H6.31Zm7.2-13.9V4H6.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.2v15.4c0 .07.03.14.1.2.06.07.13.1.2.1h11.4a.3.3 0 0 0 .2-.1.3.3 0 0 0 .1-.2V8.5h-3.6a.88.88 0 0 1-.64-.26.88.88 0 0 1-.26-.64Z"
      />
    </svg>
  </IconWrapper>
);
