import { type Column } from '@tanstack/react-table';
import { useState, useEffect } from 'react';
import { Textfield, type TextfieldProps } from '../../../../Input/Text/Textfield';

export const Filter = <T,>({ column }: { column: Column<T> }) => {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  if (filterVariant === 'range') {
    return (
      <div>
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number] | undefined)?.[0] ?? ''}
          onChange={(value) => {
            column.setFilterValue((old: [number, number] | undefined) => [value, old?.[1]]);
          }}
          placeholder={`Min`}
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number] | undefined)?.[1] ?? ''}
          onChange={(value) => {
            column.setFilterValue((old: [number, number] | undefined) => [old?.[0], value]);
          }}
          placeholder={`Max`}
        />
      </div>
    );
  } else if (filterVariant === 'select') {
    return <strong>Not yet implemented</strong>;
  } else {
    return (
      <DebouncedInput
        onChange={(value) => {
          column.setFilterValue(value);
        }}
        placeholder={`Search...`}
        type="text"
        value={(columnFilterValue ?? '') as string}
      />
      // See faceted column filters example for datalist search suggestions
    );
  }
};

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & TextfieldProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => {
      clearTimeout(timeout);
    };
  }, [debounce, onChange, value]);

  return (
    <Textfield
      {...props}
      slim={true}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
}
