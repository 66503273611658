import { PageNumberButton } from './styles';

type PageNumberProps = {
  currentPage: number;
  index: number;
  onClick: (index: number) => void;
};

export const PageNumber = ({ currentPage, index, onClick }: PageNumberProps) => (
  <PageNumberButton
    variant={index === currentPage ? 'primary' : 'secondary'}
    onClick={() => {
      onClick(index);
    }}
    isActive={index === currentPage}
    aria-label={`Go to page ${index + 1}`}
  >
    {index + 1}
  </PageNumberButton>
);
