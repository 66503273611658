import {
  type ChangeEvent,
  type FocusEvent,
  type InputHTMLAttributes,
  type MouseEvent,
  useEffect,
  useState,
} from 'react';
import { Close } from '#icons/Close';
import { Search } from '#icons/Search';
import { IconButton } from '../../IconButton';

import { getIconColor } from '../../../helpers/inputHelpers';
import {
  InnerLabelWrapper,
  InputLabel,
  OuterLabelWrapper,
  RequiredIndicator,
} from '../../../style';
import {
  IconButtonWrapper,
  InputWrapper,
  SearchfieldError,
  SearchfieldWrapper,
  SearchIconWrapper,
  StyledSearchfield,
} from './style';

type HTMLInputElementProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'defaultValue' | 'label'
>;

export type SearchfieldProps = HTMLInputElementProps & {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onlyNumbers?: boolean;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const Searchfield = ({
  label,
  placeholder,
  disabled,
  errorMessage,
  defaultValue,
  onlyNumbers,
  onClear,
  onChange,
  ...rest
}: SearchfieldProps) => {
  const { id } = rest;
  const [searchTerm, setSearchTerm] = useState(defaultValue ?? '');
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Only allow digits
    if (onlyNumbers && !/^\d*$/.test(value)) {
      return;
    }

    setSearchTerm(value);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (rest.onFocus) {
      rest.onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (rest.onBlur) {
      rest.onBlur(e);
    }
  };

  const clearInput = (e: MouseEvent<HTMLButtonElement>) => {
    setSearchTerm('');
    if (onClear) {
      onClear(e);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (onChange) {
        onChange(searchTerm);
      }
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <SearchfieldWrapper>
      <OuterLabelWrapper>
        <InnerLabelWrapper>
          {label && <InputLabel htmlFor={id ?? ''}>{label}</InputLabel>}
          {!!rest.required && <RequiredIndicator>*</RequiredIndicator>}
        </InnerLabelWrapper>
      </OuterLabelWrapper>
      <InputWrapper>
        <SearchIconWrapper>
          <Search color={getIconColor(!!disabled, isFocused)} />
        </SearchIconWrapper>
        <StyledSearchfield
          data-testid="searchfield-input"
          placeholder={placeholder}
          disabled={disabled}
          type="search"
          role="searchbox"
          error={!!errorMessage}
          value={searchTerm}
          autoCapitalize="off"
          autoComplete="off"
          onChange={handleOnChange}
          onFocusCapture={(e) => {
            handleFocus(e);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          {...rest}
        />
        {searchTerm.length > 0 && (
          <IconButtonWrapper>
            <IconButton
              data-testid="searchfield-clear-button"
              variant="secondary"
              onClick={clearInput}
              disabled={disabled}
            >
              <Close />
            </IconButton>
          </IconButtonWrapper>
        )}
      </InputWrapper>
      {errorMessage && <SearchfieldError>{errorMessage}</SearchfieldError>}
    </SearchfieldWrapper>
  );
};
