import { z } from 'zod';

const UnitSchema = z.union([z.literal('kg'), z.literal('ton')]);

export const CsrdBaseSchema = z.object({
  id: z.string(),
  buyerName: z.string(),
  buyerPhone: z.string(),
  buyerEmail: z.string().email(),
  purchaseDate: z.string().datetime(),
  locations: z.array(z.string()),
  unit: UnitSchema,
  costCenter: z.string(),
  period: z.object({
    from: z.string().date(),
    to: z.string().date(),
  }),
  status: z.union([z.literal('pending'), z.literal('ready'), z.literal('error')]),
  downloadId: z.string().optional(), //Only present if status is 'ready'
  price: z.number(), //Currency inferred from costCenter
});

export const CsrdSchemaPending = CsrdBaseSchema.extend({
  status: z.literal('pending'),
  downloadId: z.undefined(),
});

export const CsrdSchemaError = CsrdBaseSchema.extend({
  status: z.literal('error'),
  downloadId: z.undefined(),
});

export const CsrdSchemaReady = CsrdBaseSchema.extend({
  status: z.literal('ready'),
  downloadId: z.string(),
});

export const CsrdSchema = z.discriminatedUnion('status', [
  CsrdSchemaPending,
  CsrdSchemaError,
  CsrdSchemaReady,
]);

export const CsrdPurchaseSchema = z.object({
  locations: z.array(z.string()).min(1),
  period: z.object({
    from: z.string().date(),
    to: z.string().date(),
  }),
  unit: UnitSchema,
  costCenter: z.string().min(1),
});

export const CsrdOrderReferenceSchema = z.object({
  orderReference: z.string().uuid(),
  createdAt: z.string().datetime(),
  requestedBy: z.string().email(),
  orderDetails: CsrdPurchaseSchema,
});

export type CsrdSchema = z.infer<typeof CsrdSchema>;
export type CsrdPurchase = z.infer<typeof CsrdPurchaseSchema>;
export type CsrdOrderReference = z.infer<typeof CsrdOrderReferenceSchema>;
