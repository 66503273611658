import { type ReactNode, type MouseEvent, useState, type ForwardedRef, forwardRef } from 'react';
import { type LinkProps } from 'react-router-dom';
import { ButtonSpinner, IconLeft, IconRight, Label, StyledButton, StyledLink } from './style';

export type ButtonProps = {
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text-primary' | 'text-secondary';
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  icon?: ReactNode;
  iconPlacement?: 'before' | 'after';
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  testId?: string;
  preventDoubleClick?: boolean;
  className?: string;
};

type ButtonLinkProps = ButtonProps & {
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
} & LinkProps;

function ButtonComponent(
  props: ButtonProps | ButtonLinkProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
) {
  const [disabledFromClick, setDisabledFromClick] = useState(false);

  const {
    children,
    variant,
    icon,
    iconPlacement = 'before',
    fullWidth,
    disabled,
    isLoading,
    id,
    preventDoubleClick,
    testId,
    onClick,
  } = props;

  const onButtonClick = (e: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>) => {
    if (preventDoubleClick) {
      setDisabledFromClick(true);
      setTimeout(() => {
        if (!disabled) {
          setDisabledFromClick(false);
        } else {
          setDisabledFromClick(true);
        }
      }, 100);
    }
    if (!isLoading && onClick) {
      onClick(e);
    }
  };

  const content = (
    <>
      {!isLoading && icon && iconPlacement === 'before' && <IconLeft>{icon}</IconLeft>}
      {children && (
        <Label variant={variant} icon={!!icon} iconPlacement={iconPlacement} isLoading={isLoading}>
          {children}
        </Label>
      )}
      {isLoading && <ButtonSpinner color="currentColor" />}
      {!isLoading && icon && iconPlacement === 'after' && <IconRight>{icon}</IconRight>}
    </>
  );

  if (isLink(props)) {
    const buttonLinkProps = props as ButtonLinkProps;
    const rest = { ...buttonLinkProps };

    return (
      <StyledLink
        id={id}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        data-testid={testId ?? 'button'}
        onClick={onButtonClick}
        variant={variant ?? 'primary'}
        fullWidth={fullWidth}
        disabled={disabledFromClick || disabled}
        isLoading={isLoading}
        hasIcon={!!icon}
        {...rest}
      >
        {content}
      </StyledLink>
    );
  } else {
    const rest = { ...props };

    return (
      <StyledButton
        id={id}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        data-testid={testId ?? 'button'}
        onClick={onButtonClick}
        variant={variant ?? 'primary'}
        fullWidth={fullWidth}
        disabled={disabledFromClick || disabled}
        isLoading={isLoading}
        hasIcon={!!icon}
        {...rest}
      >
        {content}
      </StyledButton>
    );
  }
}

ButtonComponent.displayName = 'Button';

function isLink(props: ButtonProps | (ButtonProps & LinkProps)): props is ButtonProps & LinkProps {
  return 'to' in props;
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps | ButtonLinkProps
>(ButtonComponent);
