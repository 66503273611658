import { type FunctionComponent } from 'react';
import { type IconProps } from '../types';
import { IconWrapper } from '../style';

export const Print: FunctionComponent<IconProps> = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M2.625 18.875H6.375V21.375C6.375 21.72 6.65499 22 7 22H17C17.345 22 17.625 21.72 17.625 21.375V18.875H21.375C21.72 18.875 22 18.595 22 18.25V8.25C22 7.90499 21.72 7.625 21.375 7.625H17.625V2.625C17.625 2.27999 17.345 2 17 2H7C6.65499 2 6.375 2.27999 6.375 2.625V7.625H2.625C2.27999 7.625 2 7.90499 2 8.25V18.25C2 18.595 2.27999 18.875 2.625 18.875ZM16.375 20.75H7.625V14.5H16.375V20.75ZM7.625 3.25H16.375V7.625H7.625V3.25ZM3.25 8.875H20.75V17.625H17.625V13.875C17.625 13.53 17.345 13.25 17 13.25H7C6.65499 13.25 6.375 13.53 6.375 13.875V17.625H3.25V8.875Z"
        fill={color ?? 'currentColor'}
        transform="translate(0, 0.5)"
      />
      <path
        d="M15.125 15.75H8.875C8.52999 15.75 8.25 16.03 8.25 16.375C8.25 16.72 8.52999 17 8.875 17H15.125C15.47 17 15.75 16.72 15.75 16.375C15.75 16.03 15.47 15.75 15.125 15.75Z"
        fill={color ?? 'currentColor'}
        transform="translate(0, 0.5)"
      />
      <path
        d="M15.125 18.25H8.875C8.52999 18.25 8.25 18.53 8.25 18.875C8.25 19.22 8.52999 19.5 8.875 19.5H15.125C15.47 19.5 15.75 19.22 15.75 18.875C15.75 18.53 15.47 18.25 15.125 18.25Z"
        fill={color ?? 'currentColor'}
        transform="translate(0, 0.5)"
      />
    </svg>
  </IconWrapper>
);
